import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  Output,
  EventEmitter,
  Input,
  HostListener,
} from "@angular/core";
import { Router } from "@angular/router";
import { LanguageService } from "../../../../dynamic-components/utils/language.service";
import { ProfileService } from "../../services/profile.service";

enum LanguageTopicTag {
  English = "INFORMATION FOR NEW SUPPLIERS",
  Italiy = "INFORMATIVA PER I NUOVI FORNITORI",
  Spanish = "INFORMACIÓN PARA NUEVOS PROVEEDORES",
  Portuguese = "INFORMAÇÕES PARA NOVOS FORNECEDORES",
  French = "INFORMATIONS POUR LES NOUVEAUX FOURNISSEURS",
  Chinese = "新供应商的信息",
  Japanese = "新規サプライヤー向けの情報",
}
enum LanguageOfAccept {
  English = "I Understand",
  Italiy = "Ho capito",
  Spanish = "Lo entiendo",
  Portuguese = "Eu compreendo",
  French = "Je comprends",
  Chinese = "我知道了",
  Japanese = "内容を理解しました",
  
}

@Component({
  selector: "app-accept-modal-supplier",
  templateUrl: "./accept-modal-supplier.component.html",
  styleUrls: ["./accept-modal-supplier.component.sass"],
})
export class AcceptModalSupplierComponent implements OnInit {
  @Input() footerLink: string;
  @Output() acceptEvent: EventEmitter<boolean>;
  @ViewChild("acceptScroll") acceptScroll: ElementRef;

  selectLanguageShow = false;
  TopicTag = LanguageTopicTag.English;
  Accept = LanguageOfAccept.English;
  selectedLanguage = "English";
  displayLanguageText = "English";
  language = ["English", "Italiano", "Spanish", "Portuguese", "Français", "简体中文", "日本語"];
  Business_Ethics_site =
    "Link to Supplier's Code of Conduct & Accenture's Code of Business Ethics site";
  GuidelinesSite =
    "Link al sito delle Linee guida sull'accessibilità dei contenuti web";
  Email_for_Accounts =
    "E-mail per l'assistenza della Contabilità Fornitori o degli Acquisti";
  procurementSupport: string;

  constructor(
    private router: Router,
    private languageService: LanguageService,
    private profileService: ProfileService
  ) {
    this.acceptEvent = new EventEmitter();
  }
  Accessibility_Guidelines_site = this.languageService.getValue(
    "static.supplier.accept_modal_supplier.Accessibility_Guidelines_site"
  );
  ngOnInit() {
    if (this.profileService.supplierModel.RequestForm.RequestorDetailsInfo.SupplierCountry.includes('CN') && this.profileService.supplierModel.RequestForm.RequestorDetailsInfo.SupplierCountry.length == 1) {
      this.selectLanguageClick("简体中文");
    }
    const {procurementSupport} = JSON.parse(localStorage.getItem("externalEmails")) ?? {};
      this.procurementSupport = procurementSupport;
    // console.log(this.language,'===============>');
  }
  selectLanguageClick(ev: string) {
    this.selectedLanguage = ev;
    this.selectLanguageShow = false;
    this.displayLanguageText = ev;
    if (ev === "Italiano") {
      ev = "Italiy";
    } else if(ev === "Français"){
      ev = "French";
    } else if (ev === "简体中文") {
      ev = "Chinese";
    }  else if (ev === "日本語") {
      ev = "Japanese";
    }
    this.TopicTag = LanguageTopicTag[ev];
    this.Accept = LanguageOfAccept[ev];
  }

  routerToHelp(): void {
    this.router.navigate([`/supplier/helpPage`]);
  }

  acceptModal(flag): void {
    if (flag === "accept") {
      this.acceptEvent.emit(true);
    }
  }
}
