
import { RoleName } from "src/app/interfaces/buyer";

export function isPplusUser(role){   
    if(role === RoleName.ProcurementReviewerEditor){
      return true;
    }
    else{
      return false;
    }
  }