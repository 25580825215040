import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LayoutComponent } from "./views/layout/layout.component";
import { Routes, RouterModule } from "@angular/router";
import { DashboardComponent } from "./views/dashboard/dashboard.component";
import { SharedModule } from "src/app/shared/shared.module";
import { HeaderComponent } from "./views/header/header.component";
import { FormsModule } from "@angular/forms";
import { NgxPaginationModule } from "ngx-pagination";
import { SupplierDetailComponent } from "./views/supplier-detail/supplier-detail.component";
import {CsnsOnboardingProcessComponent} from "../buyer/views/onboarding/csns-onboarding-process/csns-onboarding-process.component";
import { OnBoardingLayoutComponent } from "./views/onboarding/layout/layout.component";
import { OnBoardingHeaderComponent } from "./views/onboarding/header/header.component";
import { RequestFormComponent } from "./views/onboarding/request-form/request-form.component";
import { RequestorDetailsComponent } from "./views/onboarding/requestor-details/requestor-details.component";

import { BuyerService } from "src/app/services/buyer.service";
import { OnBoardingResolver } from "../buyer/services/onboarding.resolver.service";
import { PagePermissionResolver } from "../buyer/services/pagePermission.resolver.service";

import { BuyerGuardService } from "./buyer.guard";
import { OnBoardingDeclarationComponent } from "./views/onboarding/declaration/declaration.component";
import { CompletedComponent } from "./views/onboarding/completed/completed.component";
import { BuyerOnboardingProcessComponent } from "./views/onboarding/process/onboarding-process-component";
import { AdminLayoutComponent } from "./views/management/admin-layout/admin-layout.component";
import { SupplierAdminLayoutComponent } from "./views/supplierManagement/admin-layout/admin-layout.component";
import { SupplierUserEditComponent } from "./views/supplierManagement/user-edit/user-edit.component";
import { SupplierUsersComponent } from "./views/supplierManagement/users/users.component";

// history
import { HistoryHeaderComponent } from "./views/history/history-header/history-header.component";
import { HistoryBuyerComponent } from "./views/history/history-buyer/history-buyer.component";
import { HistoryLayoutComponent } from "./views/history/history-layout/history-layout.component";
import { UsersComponent } from "./views/management/users/users.component";
import { UserEditComponent } from "./views/management/user-edit/user-edit.component";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { AcceptModalComponent } from "./views/accept-modal/accept-modal.component";
import { BuyerManagementGuardService } from "./management.guard";
import { CanDeactivateGuard } from "src/app/can-deactivate.guard";
import { ReportService } from "./services/report.service";
import { AuditTrailHistoryComponent } from "./views/history/audit-trail-history/audit-trail-history.component";
import { HelppageComponent } from "./views/helppage/helppage.component";
import { ChangeRequestDialogComponent } from "./views/change-request/change-request-dialog.component";
import { PaymentTermComponent } from "./views/change-request/payment-term/payment-term.component";
import { AuditTrailChangeRequestComponent } from "./views/history/audit-trail-change-request/audit-trail-change-request.component";
import { CompanyComponent } from "./views/change-request/company/company.component";
import { SupplierSetupProcessService } from "./services/supplierSetupProcess.service";
import { MigrateSetupProcessService } from "./services/migrateSetupProcess.service";
import { EditHoldComponent } from "./views/edit-hold/edit-hold.component";
import { RemoveHoldComponent } from "./views/edit-hold/remove-hold/remove-hold.component";
import { ExtendHoldComponent } from "./views/edit-hold/extend-hold/extend-hold.component";
import { HoldRequestComponent } from "./views/hold-request/hold-request.component";

import { HoldOnService } from "src/app/services/HoldOn";
import { OnHoldHistoryComponent } from "./views/history/on-hold-history/on-hold-history.component";
import { AmendSpendCommodityCategoryGroupComponent } from "./views/change-request/amend-spend-commodity-category-group/amend-spend-commodity-category-group.component";
import { DashboardSearchComponent } from "./views/dashboard-search/dashboard-search.component";
import { DashboardSearchBarComponent } from "./views/dashboard-search-bar/dashboard-search-bar.component";
import { TranslateModule } from "@ngx-translate/core";
import { RiskAssessmentComponentCR } from "./views/change-request/risk-assessmentCR/risk-assessmentCR.component";
const routes: Routes = [
  {
    path: "buyer",
    canActivate: [BuyerGuardService],
    component: LayoutComponent,
    children: [
      {
        path: "",
        canActivateChild: [BuyerGuardService],
        resolve: {
          PagePermission: PagePermissionResolver,
        },
        component: DashboardComponent,
        data: { title: 'Dashboard'}
      },
      {
        path: "search/:key1/:key2",
        canActivateChild: [BuyerGuardService],
        component: DashboardSearchComponent,
        data: { title: 'DashboardSearch'}
      },
      {
        path: "supplier/:supplierCode",
        // resolve: {
        //   PagePermission: PagePermissionResolver,
        // },
        canActivateChild: [BuyerGuardService],
        component: SupplierDetailComponent,
        data: { title: 'SupplierDetail'}
      },
      {
        path: "helpPage",
        canActivateChild: [BuyerGuardService],
        component: HelppageComponent,
        data: { title: 'helpPage'}
      },
      {
        path: "onboarding/process/:supplierCode",
        resolve: {
          PagePermission: PagePermissionResolver,
        },
        canActivateChild: [BuyerGuardService],
        component: BuyerOnboardingProcessComponent,
        data: { title: 'BuyerOnboardingProcess'}
      },
      {
        path: "onboarding/:code",
        resolve: {
          PagePermission: PagePermissionResolver,
          SupplierModel: OnBoardingResolver,
        },
        canActivate: [BuyerGuardService],
        canActivateChild: [BuyerGuardService],
        component: OnBoardingLayoutComponent,
        data: { title: 'OnBoardingLayout'},
        children: [
          {
            path: "step1",
            component: RequestorDetailsComponent,
            canDeactivate: [CanDeactivateGuard],
            data: { title: 'step1'}
          },
          {
            path: "step2",
            component: RequestFormComponent,
            canDeactivate: [CanDeactivateGuard],
            data: { title: 'step2'}
          },
          {
            path: "step3",
            component: OnBoardingDeclarationComponent,
            canDeactivate: [CanDeactivateGuard],
            data: { title: 'step3'}
          },
          { 
            path: "step4", 
            component: CompletedComponent, 
            data: { title: 'step4'} },
          {
            path: "stepCRCoupa",
            component: RiskAssessmentComponentCR,
            canDeactivate: [CanDeactivateGuard],
            data: { title: 'stepCRCoupa'}
          },
        ],
      },
      // {
      //   path: "management",
      //   component: AdminLayoutComponent,
      //   canActivate: [BuyerManagementGuardService],
      //   children: [
      //     { path: "users", component: UsersComponent },
      //     { path: "user-create", component: UserEditComponent },
      //     { path: "user-edit/:mode/:mail", component: UserEditComponent },
      //   ],
      // },
      {
        path: "supplierManagement",
        component: SupplierAdminLayoutComponent,
        children: [
          { path: "users/:supplierCode", component: SupplierUsersComponent },
          {
            path: "user-create/:supplierCode",
            component: SupplierUserEditComponent,
          },
          {
            path: "user-edit/:mode/:mail/:supplierCode",
            component: SupplierUserEditComponent,
          },
        ],
      },
      {
        path: "history/:code/:step",
        resolve: {
          PagePermission: PagePermissionResolver,
          SupplierModel: OnBoardingResolver,
        },
        canActivate: [BuyerGuardService],
        canActivateChild: [BuyerGuardService],
        component: HistoryLayoutComponent,
        children: [{ path: "", component: HistoryBuyerComponent }],
      },
    ],
  },
];

@NgModule({
  declarations: [
    LayoutComponent,
    DashboardComponent,
    HeaderComponent,
    SupplierDetailComponent,
    OnBoardingLayoutComponent,
    OnBoardingHeaderComponent,
    RequestFormComponent,
    RequestorDetailsComponent,
    OnBoardingDeclarationComponent,
    BuyerOnboardingProcessComponent,
    CompletedComponent,
    AdminLayoutComponent,
    UsersComponent,
    HistoryHeaderComponent,
    HistoryBuyerComponent,
    HistoryLayoutComponent,
    UserEditComponent,
    AcceptModalComponent,
    AuditTrailHistoryComponent,
    HelppageComponent,
    ChangeRequestDialogComponent,
    AuditTrailChangeRequestComponent,
    PaymentTermComponent,
    CompanyComponent,
    EditHoldComponent,
    RemoveHoldComponent,
    ExtendHoldComponent,
    HoldRequestComponent,
    OnHoldHistoryComponent,
    SupplierAdminLayoutComponent,
    SupplierUserEditComponent,
    SupplierUsersComponent,
    AmendSpendCommodityCategoryGroupComponent,
    DashboardSearchComponent,
    DashboardSearchBarComponent,
    RiskAssessmentComponentCR,
    CsnsOnboardingProcessComponent
  ],

  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    MatSlideToggleModule,
    NgxPaginationModule,
    RouterModule.forRoot(routes, { useHash: true }),
    TranslateModule,
  ],
  exports: [RouterModule, AcceptModalComponent,CsnsOnboardingProcessComponent],
  providers: [
    BuyerService,
    OnBoardingResolver,
    PagePermissionResolver,
    ReportService,
    SupplierSetupProcessService,
    MigrateSetupProcessService,
    HoldOnService,
  ],
})
export class BuyerModule {}
