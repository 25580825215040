import { Injectable } from "@angular/core";
import { HttpService } from "./api/http.service";
import { environment } from "../../environments/environment";
import { SupplierModel } from "../interfaces/supplierModel";
import { AlertInfo } from "../interfaces/alertInfo";
import { SupplierMappingDTO } from "src/app/interfaces/mapping";
import { Task } from "../interfaces/workflow";
import { SupplierProfileModel } from "../interfaces/SupplierProfileModel";

@Injectable()
export class SupplierService {
  baseUrl = environment.gateway;
  supplierUrl = environment.supplierGateway;
 
  constructor(private http: HttpService) {}
 
  public supplierGet(companyNb: string, infoType: string): Promise<any> {
    const url = `${this.baseUrl}/supplier/${companyNb}/${infoType}`;
    return this.http.GetPromise(url);
  }
 
  public supplierPost(
    companyNb: string,
    infoType: string,
    model: any
  ): Promise<any> {
    const url = `${this.baseUrl}/supplier/${companyNb}/${infoType}`;
    return this.http.PostPromise(url, model);
  }
 
  public supplierPut(
    companyNb: string,
    infoType: string,
    model: any
  ): Promise<any> {
    const url = `${this.baseUrl}/supplier/${companyNb}/${infoType}`;
    return this.http.PostPromise(url, model);
  }
 
  public supplierRegister(companyNb: string, model: any): Promise<any> {
    const url = `${this.baseUrl}/supplier/register/${companyNb}`;
    return this.http.PostPromise(url, model);
  }
 
  public supplierGetPublic(companyNb: string): Promise<any> {
    const url = `${this.baseUrl}/supplierPublicInfo/${companyNb}`;
    return this.http.GetPromise(url);
  }
 
  public getGlobalFieldData(param): Promise<any> {
    const url = `${this.baseUrl}/supplier/getGlobalQuestionAnswers`
    return this.http.PostPromise(url, param);
  }
 
  public supplierGetPublicHistory(companyNb: string): Promise<any> {
    const url = `${this.baseUrl}/supplierPublicHistory/${companyNb}`;
    return this.http.GetPromise(url);
  }
 
  public supplierGetStatus(companyNb: string): Promise<any> {
    const url = `${this.baseUrl}/supplierGetStatus/${companyNb}`;
    return this.http.GetPromise(url);
  }
 
  public supplierGetPrivateData(companyNb: string): Promise<any> {
    const url = `${this.baseUrl}/supplierPrivateData/${companyNb}`;
    return this.http.GetPromise(url);
  }
 
  public onBoardingStatus(model: any): Promise<any> {
    const url = `${this.baseUrl}/supplierPrivateData/onboarding/status`;
    return this.http.PostPromise(url, model);
  }
 
  public supplierGetonBoardingHistory(supplierCompanyNb: string): Promise<any> {
    const url = `${this.baseUrl}/supplierPrivateData/onboarding/history`;
    return this.http.PostPromise(url, {
      buyerCompanyNb: "accenture",
      supplierCompanyNb: supplierCompanyNb,
      buyerOrgName: "buyer",
      supplierOrgName: supplierCompanyNb,
    });
  }
 
  public supplierLatestNotification(supplierCompanyNb: string) {
    const url = `${this.baseUrl}/supplier/notification/latest/${supplierCompanyNb}`;
    return this.http.GetPromise(url);
  }
 
  public notificationList(userID: number, cp: number, ps: number) {
    const url = `${this.baseUrl}/supplier/myNotifications`;
    if (environment.languageSwitch && environment.role == "supplier") {
      let lang = "en";
      if (localStorage.getItem("language")) {
        lang = localStorage.getItem("language");
      }
      return this.http.PostPromise(url, { userID, cp, ps, lang });
    } else {
      return this.http.PostPromise(url, { userID, cp, ps });
    }
  }
 
  public markAsRead(userID: number, notificationID: string) {
    const url = `${this.baseUrl}/supplier/notifications/markAsRead`;
    return this.http.PostPromise(url, { userID, notificationID });
  }
 
  public supplierUnderstand(supplierCode: string) {
    const url = `${this.baseUrl}/supplier/understand/${supplierCode}`;
    return this.http.GetPromise(url);
  }
 
  public supplierAccept(
    supplierCode: string,
    task: Task,
    supplierEmail: string
  ) {
    const url = `${
      this.baseUrl
    }/supplier/accept/${supplierCode}/${supplierEmail}?no_cache=${new Date().getTime()}`;
    return this.http.PostPromise(url, task);
  }

  public newSupplierAccept( supplierCode: string, task: Task, supplierEmail: string ) {
    const url = `${this.supplierUrl}/supplier/accept/${supplierCode}/${supplierEmail}?no_cache=${new Date().getTime()}`;
    return this.http.PostPromise(url, task);
  }
 
  public supplierNormalUserAccept(supplierEmail: string, supplierCode: string) {
    const url = `${
      this.baseUrl
    }/supplierNormalUser/accept/${supplierEmail}/${supplierCode}?no_cache=${new Date().getTime()}`;
    return this.http.GetPromise(url);
  }
 
  // refactor supplier api
  public querySupplierProfile(supplierCode: string): Promise<SupplierModel> {
    const url = `${this.baseUrl}/supplier/profile/${supplierCode}`;
    return this.http.GetPromise(url);
  }
 
  public queryNewSupplierProfile(supplierCode: string): Promise<SupplierModel> {
    const url = `${this.supplierUrl}/supplier/profile/${supplierCode}`;
    return this.http.GetPromise(url);
  }
 
  public updateProfileNode(supplierCode: string): Promise<any> {
    const url = `${this.baseUrl}/supplier/updateProfileNode`;
    return this.http.PostPromise(url, { supplierCode });
  }
 
  public initSupplier(supplierCode, email) {
    const url = `${this.baseUrl}/supplier/init`;
    return this.http.PostPromise(url, { supplierCode, email });
  }

  public newInitSupplier(supplierCode, email) {
    const url = `${this.supplierUrl}/supplier/init`;
    return this.http.PostPromise(url, { supplierCode, email });
  }
 
  public multipleSupplierList(email) {
    const url = `${this.baseUrl}/supplier/initSupplierList`;
    return this.http.PostPromise(url, { email });
  }
 
  public verifyTSMSupplierProfileStatus(supplierCode: string, tsmId: string) {
    const url = `${this.baseUrl}/supplier/verify`;
    return this.http.PostPromise(url, { supplierCode, tsmId });
  }
 
  public verifyTSMSAStatus(
    supplierCode: string,
    tsmId: string,
    supplierCountry: string[]
  ) {
    const url = `${this.baseUrl}/supplier/verify/sa`;
    return this.http.PostPromise(url, { supplierCode, tsmId, supplierCountry });
  }
 
  public multipleSupplierListTSM(tsmID, countryLocation, pocEmail) {
    const url = `${this.baseUrl}/supplier/initSupplierListTSM`;
    return this.http.PostPromise(url, { tsmID, countryLocation, pocEmail });
  }
 
  public checkSupplierMemberInfo(supplierEmail: string) {
    const url = `${this.baseUrl}/supplier/checkSupplierMemberInfo`;
    const body = { supplierEmail: supplierEmail };
    return this.http.PostPromise(url, body);
  }
 
  public getSupplierInfo(supplierCode) {
    const url = `${this.baseUrl}/supplier/getSupplierInfo`;
    const data = { supplierCode: supplierCode };
    return this.http.PostPromise(url, data);
  }
 
  public checkField(supplierCode: string, businessRegistrationNb: string) {
    const url = `${this.baseUrl}/supplier/check_field`;
    const data = {
      supplierCode: supplierCode,
      businessregistrationNb: businessRegistrationNb,
    };
    return this.http.PostPromise(url, data);
  }
 
  public supplierFileUpload(
    supplierCode,
    fileName: string,
    files
  ): Promise<any> {
    const url = `${this.baseUrl}/supplier/file/upload/${supplierCode}`;
    const formData: FormData = new FormData();
    files.forEach((file) => {
      formData.append(fileName, file._file, file._file.name);
    });
    return this.http.PostPromise(url, formData);
  }
 
  public supplierFileDownload(supplierCode, fileKey): Promise<any> {
    const url = `${this.baseUrl}/supplier/file/download/${supplierCode}`;
    return this.http.PostPromise(url, fileKey);
  }
 
  public modifyUser(user, email, supplierMembername) {
    const url = `${this.baseUrl}/supplier/management/user/modify/${email}/${supplierMembername}/`;
    return this.http.PostPromise(url, user);
  }
 
  public userExists(supplierCode: string, email) {
    const url = `${this.baseUrl}/supplier/management/user/exists/${supplierCode}/${email}/`;
    return this.http.GetPromise(url);
  }
 
  public supplierUsers(supplierCode: string) {
    const url = `${this.baseUrl}/supplier/management/users/${supplierCode}`;
    return this.http.GetPromise(url);
  }
 
  public createUser(user, type: boolean, isBuyerAdmin: boolean) {
    const url = `${this.baseUrl}/supplier/management/user/create/${type}/${isBuyerAdmin}`;
    return this.http.PostPromise(url, user);
  }
 
  public getSupplierB2CFlag(email) {
    const url = `${this.baseUrl}/supplier/management/user/getB2cFlag`;
    const data = { email: email };
    return this.http.PostPromise(url, data);
  }
 
  public supplierRoles() {
    const url = `${this.baseUrl}/supplier/management/roles`;
    return this.http.GetPromise(url);
  }
 
  /// ############# start history profile form ######
  public getProfilesForm(supplierCode: string) {
    const url = `${this.baseUrl}/buyer/profile_form/${supplierCode}`;
    return this.http.GetPromise(url);
  }
  /// ############# end history profile form ######
  public querySupplierHistories(supplierCode: string): Promise<any> {
    const url = `${this.baseUrl}/supplier/histories/${supplierCode}`;
    return this.http.GetPromise(url);
  }

  public querySupplierHistoriesEnhanced(supplierCode: string): Promise<any> {
    const url = `${this.supplierUrl}/supplier/histories/${supplierCode}`;
    return this.http.GetPromise(url);
  }
 
  public querySupplierRoles(): Promise<any> {
    const url = `${this.baseUrl}/supplier/management/roles`;
    return this.http.GetPromise(url);
  }
 
  /// ############# supplier alert bar######
 
  public queryLastAlertBar(): Promise<any> {
    const url = `${this.baseUrl}/supplier/queryLastAlertBar`;
    return this.http.PostPromise(url, {});
  }
 
  public saveAlertBar(alertInfo: AlertInfo) {
    const url = `${this.baseUrl}/supplier/saveAlertBar`;
    return this.http.PostPromise(url, alertInfo);
  }
 
  public deleteAlertBar(alertInfo: AlertInfo) {
    const url = `${this.baseUrl}/supplier/deleteAlertBar`;
    return this.http.PostPromise(url, { alertInfo });
  }
 
  public queryIsInvited(SupplierCode: string): Promise<any> {
    const url = `${this.baseUrl}/supplier/queryIsInvited`;
    return this.http.PostPromise(url, { SupplierCode });
  }
 
  public changeIsInvited(mapping: SupplierMappingDTO) {
    const url = `${this.baseUrl}/supplier/changeIsInvited`;
    return this.http.PostPromise(url, mapping);
  }
 
  // workflow profile form router
  public saveProfileFormDraft(form: SupplierModel) {
    const url = `${this.baseUrl}/supplier/profileForm/saveDraft`;
    return this.http.PostPromise(url, form);
  }
 
  public saveFinancialProfileFormDraft(form: SupplierProfileModel) {
    const url = `${this.supplierUrl}/supplier/financial/saveDraft`;
    return this.http.PostPromise(url, form);
  }
 
  public saveOrganizationProfileFormDraft(form: SupplierProfileModel) {
    const url = `${this.supplierUrl}/supplier/organization/saveDraft`;
    return this.http.PostPromise(url, form);
  }
 
  public submitProfileFormDraftForExsting(form: SupplierModel) {
    const url = `${this.baseUrl}/supplier/profileForm/submitDraft`;
    return this.http.PostPromise(url, form);
  }
 
  public submitProfileFormDraft(form: SupplierProfileModel) {
    const url = `${this.supplierUrl}/supplier/profileForm/submitDraft`;
    return this.http.PostPromise(url, form);
  }
 
  public saveGeneralProfileFormDraft(form: SupplierProfileModel) {
    const url = `${this.supplierUrl}/supplier/general/saveDraft`;
    return this.http.PostPromise(url, form);
  }

  public newresubmitProfileForm(form: SupplierProfileModel) {
    const url = `${this.supplierUrl}/supplier/profile/resubmit`;
    return this.http.PostPromise(url, form);
  }
 
  public resubmitProfileForm(form: SupplierModel) {
    const url = `${this.baseUrl}/supplier/profileForm/resubmit`;
    return this.http.PostPromise(url, form);
  }
 
  public changeProfileForm(form: SupplierModel) {
    const url = `${this.baseUrl}/supplier/profileForm/changeRequest`;
    return this.http.PostPromise(url, form);
  }

  public newchangeProfileForm(form: SupplierProfileModel) {
    const url = `${this.supplierUrl}/supplier/profile/changeRequest`;
    return this.http.PostPromise(url, form);
  }
 
  public submitMigrateProfileFormDraft(form: SupplierModel) {
    const url = `${this.baseUrl}/supplier/profileForm/migrate/submitDraft`;
    return this.http.PostPromise(url, form);
  }
 
  public updateSupplierMemberOID(data) {
    const url = `${this.baseUrl}/supplier/updateSupplierMemberOID`;
    return this.http.PostPromise(url, data);
  }
  public checkSupplierIsDeleted(supplierCode: string) {
    const url = `${this.baseUrl}/supplier/management/checkSupplierIsDeleted/${supplierCode}`;
    return this.http.GetPromise(url);
  }
  public getSupplierStatusInfo(supplierCode: string) {
    const url = `${this.baseUrl}/supplier/management/getSupplierStatusInfo/${supplierCode}`;
    return this.http.GetPromise(url);
  }
  public getCelonisSupplierExtract() {
    const url = `${this.baseUrl}/getCelonisSupplierExtract`;
    return this.http.GetPromise(url);
  }
}